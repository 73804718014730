
.chat-body-react .chat-start {
  border: 1px solid #f8d4ff;
  width: 150px;
  border-radius: 50px;
  padding: 6px 10px;
  font-size: 12px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 15px;
  background: #fff;
}
.chat-bubble.you {
    background-image: linear-gradient(to right, #dee3e8, #d8dadd, #d2ccd6, #babfc4, #acb0b3) !important;
    color: black !important;
    border-radius: 0 15px 15px 15px;
    align-self: flex-start;
    display: table;
}

.chat-bubble.me {
  background-image: linear-gradient(to right, #b7aeae, #FFFFFF, #FFFFFF, #FFFFFF, #d8cece);
  color: #888ea8;
  border-radius: 15px 0px 15px 15px;
  float: right;
  align-self: flex-end;
  display: table;
}

.chat-bubble {
  font-size: 12px;
  padding: 10px 15px;
  box-shadow: none;
  display: inline-block;
  clear: both;
  margin-bottom: 10px;
  box-shadow: 0 5px 5px rgb(0 0 0 / 2%);
}

.brochure-btn, .main-menu-btn {
  margin: 2px 3px;
  padding: 6px 10px;
  font-size: 12px;
  border: 1px solid #495057;
}