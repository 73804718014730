*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.App{
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.main_img, .lg_img{
    width: 500px;
}


/* .AppHeader {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.18)
} */

.sidebar {
    background: rgb(0, 198, 125);
    color: #fff;
    height: 100vh;
}

.SideMenuAndPageContent {
   display: flex;
   flex: 1;
   justify-content: flex-start;
   align-items: flex-start;
   
}

.SideMenu{
    height: 100%;
}

.iconFeature {
    border-radius: 20px;
    font-size: 35px;
    padding: 14px;
}

.iconColor1 {
    color: rgb(8, 98, 70);
    background-color: rgb(174, 233, 174);
}

.iconColor2 {
    color: #d81b60;
    background-color: #e292ad;
}

.iconColor3 {
    color: #1a73e8;
    background-color: #58a5e7d6;
}

.iconColor4 {
    color: #191919;
    background-color: #42424ac4;
}

.Footer{
    background-color: rgb(8, 98, 70);
    color: #ffffff;
    padding: 2rem 0;
}

.ft-1 h5 {
   font-weight: 700;
   letter-spacing: 2px;
   padding: 0.5rem 0;
}

.ft-1 p {
    padding: 1rem 5rem 1rem 0;
}

.footer-icons i {
    padding:0.4rem 0.5rem;
    margin: 0 0.5rem;
    background-color: #a9a9a9;
    color: rgb(8, 98, 70);
    border-radius: 50%;
}

.footer-icons i:hover {
    background-color: rgb(8, 98, 70);
    color: #fff;
    cursor: pointer;
    transition: 0.6s;
}

.ft-3 p {
    padding: 1rem 5rem 1rem 0;
}

.ft-3 p a {
    color: #fff;
    text-decoration: none;
    font-size: 18px;
    font-weight: 800;
}

.forgot-password {
    max-width: 400px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background: #f9f9f9;
}

.form-group {
    margin-bottom: 15px;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
}

button {
    width: 100%;
    padding: 10px;
    background: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background: #218838;
}

.success-message {
    color: green;
    margin-top: 10px;
}

.error-message {
    color: red;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
    .main_img, .lg_img{
        width: 100%;
    }
}